<template>
  <div class="home">
    <vue-headful
      title="建設現場のリモート業務支援 CSRSシステム"
      description="Description here"
      keywords="明るい未来のためのエンジニアリング"
    />
    <type-five
      :image-src="section_2[0].image"
      :title="section_2[0].title"
      :description="section_2[0].description"
    />
    <type-three />
    <type-four>
      <v-row>
        <v-col v-for="(item, id) in section_3" :key="id" cols="12" sm="3">
          <card-type-one
            :image-src="item.image"
            :title="item.title"
            :description="item.description"
          />
        </v-col>
      </v-row>
    </type-four>
    <type-five
      :image-src="section_5[0].image"
      :title="section_5[0].title"
      :description="section_5[0].description"
    />
    <v-container class="pb-pb-7 pb-sm-12">
      <type-six
        v-for="(item, id) in section_6"
        :key="id"
        :image-src="item.image"
        :title="item.title"
        :description="item.description"
        :style="[id % 2 === 1 ? { 'flex-direction': 'row-reverse' } : {}]"
      />
      <go-to-contact />
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="d-sm-flex align-sm-center">
          <div class="text-center text-sm-left">
            <h1 class="text-title mb-3 mb-sm-12">
              Construction Site <br />
              Remote Support System <br />
              を使ってつながろう!
            </h1>

            <div class="pt-5">
              <table>
                <tr>
                  <th colspan="2" class="text-title">
                    CSRSのご紹介ビデオ
                  </th>
                </tr>
                <tr>
                  <td>
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/SlV1-8X8y0E"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <a href="/pdf/csrs.pdf" download="csrs.pdf"
                      >パンフレットのダウンロード</a
                    >
                  </td>
                </tr>
              </table>
            </div>

            <div class="text-center">
              <go-to-contact-type-two />
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img
            class="mx-auto"
            contain
            max-width="475"
            src="@/assets/images/mobile-app.png"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid style="background-color: #FAF7F5">
      <fee-table />
    </v-container>
    <type-five
      :image-src="section_7[0].image"
      :title="section_7[0].title"
      :description="section_7[0].description"
    />
    <type-seven />
  </div>
</template>

<script>
import TypeThree from "@/components/TypeThree";
import TypeFour from "@/components/TypeFour";
import CardTypeOne from "@/components/CardTypeOne";
import TypeFive from "@/components/TypeFive";
import TypeSix from "@/components/TypeSix";
import TypeSeven from "@/components/TypeSeven";
import GoToContact from "@/components/GoToContact";
import FeeTable from "@/components/FeeTable";
import GoToContactTypeTwo from "@/components/GoToContactTypeTwo";

export default {
  components: {
    GoToContactTypeTwo,
    FeeTable,
    GoToContact,
    TypeSeven,
    TypeSix,
    TypeFive,
    CardTypeOne,
    TypeFour,
    TypeThree
  },
  data() {
    return {
      section_2: [
        {
          image: "images/image-5.webp",
          title:
            "Construction Site Remote Support System <br />サービスのご紹介",
          description:
            "本サービスでは、現場と社内を簡単にリモートでつなぎ、現場に必要なサポートができるサービスです" +
            "<br />" +
            "このサービスを活用して、他社との差別化を実現して" +
            "<br />" +
            "現場業務の効率向上を実現しましょう" +
            "<br />" +
            "「" +
            "<a href='https://www.youtube.com/embed/SlV1-8X8y0E' class='white--text'>CSRSのご紹介ビデオ</a>" +
            "」「" +
            " <a href='/pdf/csrs.pdf' download='csrs.pdf' class='white--text' >パンフレット</a>" +
            "」"
        }
      ],
      section_3: [
        {
          image: "images/image-14.webp",
          title: "会社ライセンス取得",
          description:
            "まずは事業所で登録1カ月単位の契約で1事業所+1現場のライセンスを発行致します"
        },
        {
          image: "images/image-15.webp",
          title: "現場単位でライセンスを追加取得できます",
          description:
            "社内と現場を簡単につなぐ事で、業務効率が各段に向上させることができます"
        },
        {
          image: "images/image-16.webp",
          title: "リモート技術者支援システムとの連携可能",
          description:
            "社外の専門知識が必要な場合、リモート支援システムとの連携が可能です。<br />" +
            "支援サポートにより、業務の可能性が拡張します"
        },
        {
          image: "images/image-17.webp",
          title: "簡単支払い",
          description: "支払いはクレジット支払いやチャージ決済で簡単です"
        }
      ],
      section_5: [
        {
          image: "images/image-2.webp",
          title: "Construction Site Remote Support System<br />導入のメリット",
          description:
            "Construction Site Remote Support Systemでサポートできるサービスを紹介します"
        }
      ],
      section_6: [
        {
          image: "images/image-3.webp",
          title: "簡単リモート打ち合わせ業務",
          description: "オンラインで簡単に離れた仲間とつながることができます"
        },
        {
          image: "images/image-4.webp",
          title: "遠隔で現場のPC業務ができます",
          description:
            "このシステムを使用すると、​簡単に現場のPCに入ることができ、現場の業務をサポートできます"
        },
        {
          image: "images/image-5.webp",
          title: "遠隔技術者支援と接続",
          description:
            "別途機能として、リモート技術者支援サービスと接続できます<br />" +
            "リモート技術者の力を活用して、可能性を拡張できます"
        }
      ],
      section_7: [
        {
          image: "images/image-6.webp",
          title: "導入実績",
          description: ""
        }
      ],
      section_9: [
        {
          image: "images/image-7.webp",
          title: "建設業が変わる<br/>現場からイノベーションを",
          description:
            "<b class='font-18'>現場を支えるIT活用を推進しよう</b><br />" +
            "<br />" +
            "現場は戦場です" +
            "<br />" +
            "いろんな技術革新が進んでも、使ってもらえない" +
            "<br />" +
            "その答えは単純で、現場で懸命している状況下では、ただいいだけでは使えない、本当に使いやすく即戦力でないと使えない、そんなに甘くないのが、現場です" +
            "<br />" +
            "そんな厳しい現場から支持していただけるようなサービスを提供します"
        }
      ],
      section_10: [
        {
          image: "images/image-8.webp",
          title: "弊社について",
          description: "建設業×AIを実践で活用します"
        }
      ],
      section_12: [
        {
          image: "images/image-1.webp",
          title: "リモート技術者支援<br />サービスのご紹介",
          description:
            "本サービスは、現場と技術者をリモートでつなぎ、現場に都度必要な技術のサポートができるサービスです" +
            "<br />" +
            "例えば、見積・CAD図作成・３D設計データ作成・現場での施工管理や安全書類作成業務といった技術が必要な業務を遠隔で技術者が時間単位でサポートしてくれるため、効率向上が期待されます" +
            "<br />" +
            "IT技術を駆使して、高齢技術者や健常ではない技術者の方々が活躍できる新たな働き方を創ります。"
        }
      ],
      section_13: [
        {
          bg_color: "#fff",
          text_color: "#000",
          image: "images/ハンギングビル建設.webp",
          title: "日本の建設技術を守る",
          subtitle: "私たちの使命",
          description:
            "リモート技術者支援サービスは、コロナウイルスへの感染を防止する新たな働き方の挑戦です。<br />" +
            "3密を防ぎながら、熟練技術者・女性技術者・高齢技術者・障害を抱えた技術者が活躍できる新しい働き方を確立し、これまでの完全現場主義から遠隔支援という働き方に向き合いながら、日本の建設技術がさらに向上していく一つの新しい選択肢になるように挑戦していきます<br />" +
            "日本の高度な技術を承継していくための、一つの可能性として皆様の協力でこの新しい働き方を育てていきましょう"
        }
      ],
      section_14: [
        {
          image: "images/client-1.webp",
          title: "登録",
          description: "登録フォームより記載"
        },
        {
          image: "images/client-2.webp",
          title: "面談　契約",
          description:
            "リモート技術者の一覧より選択し面談　気に入った熟練者とオンラインで契約"
        },
        {
          image: "images/client-3.webp",
          title: "リモート業務～評価",
          description:
            "熟練者と打ち合わせをしながら、業務を遂行していきます　最後にお互いの評価をして完了"
        },
        {
          image: "images/client-4.webp",
          title: "支払い",
          description: "​業務完了後、オンラインで決済で業務完了"
        }
      ],
      section_15: [
        {
          image: "images/image-2.webp",
          title: "サービス一覧",
          description: "建設現場の様々な要望にリモートで支援するサービスです"
        }
      ],
      section_16: [
        {
          image: "images/image-10.webp",
          title: "リモート３D設計業務",
          description:
            "<span class='font-18'>３D設計データを遠隔で作成します</span><br /><br />" +
            "ICT施工関する３D設計データを作成致します<br />" +
            "測量・施工・BIM/CIMデータ等も作成可能です"
        },
        {
          image: "images/image-11.webp",
          title: "リモート測量　ドローン測量のみ",
          description:
            "<span class='font-18'>現地での離陸着陸は現場職員様が行い、 リモートでドローン測量をサポートします</span><br /><br />" +
            "現場には必要なドローンや測量機器をこちらから郵送して、標定点位置決め、ドローンのフライトプラン、設定操作はリモートでこちらから指示、設定を行います。"
        },
        {
          image: "images/image-12.webp",
          title: "リモート見積",
          description:
            "<span class='font-18'>熟練者が見積業務を代行します</span><br /><br />" +
            "見積は受注に大きく影響します。<br />" +
            "根拠となる代価表や単価等は、各企業のその時の事情で 変動するため答えがありません<br />" +
            "お客様が納得する見積を作成することが重要であり不可欠です<br />" +
            "このリモート見積業務では、熟練者の技術力をお借りして 見積作成を実施します"
        },
        {
          image: "images/image-4.webp",
          title: "リモート見積",
          description:
            "<span class='font-18'>熟練者が見積業務を代行します</span><br /><br />" +
            "見積は受注に大きく影響します。<br />" +
            "根拠となる代価表や単価等は、各企業のその時の事情で 変動するため答えがありません<br />" +
            "お客様が納得する見積を作成することが重要であり不可欠です<br />" +
            "このリモート見積業務では、熟練者の技術力をお借りして 見積作成を実施します"
        }
      ]
    };
  }
};
</script>

<template>
  <div class="type-seven">
    <v-container>
      <div class="content">
        <h3 class="text-title">TOTALMASTERS株式会社</h3>
        <p>
          お客様からいただく見積もり依頼に、本サービスのリモート見積を活用させていただきました。
        </p>
        <p>
          リモートで熟練者にご支援いただき、素晴らしい内容の見積もりを作っていただき、受注を達成できました
        </p>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TypeSeven"
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.type-seven
  padding: 32px 0
  background-color: #FAF7F5
  .container
    max-width: 980px
  .text-title
    color: #0066E3
    margin-bottom: 34px
@media #{map-get($display-breakpoints, 'sm-and-up')}
  .type-seven
      padding: 120px 0
      background-color: #FAF7F5
      .container
          max-width: 980px
      .text-title
          color: #0066E3
          margin-bottom: 34px
</style>

<template>
  <div class="fee-table pb-7 pb-sm-12">
    <section-title>料金体系</section-title>
    <div>
      <table>
        <tr>
          <th colspan="2" class="text-title">無料体験版</th>
        </tr>
        <tr>
          <th>内容</th>
          <td>・人数定員 4名前後 web meetingのみ</td>
        </tr>
      </table>
    </div>
    <div class="pt-5">
      <table>
        <tr>
          <th colspan="2" class="text-title">有料プラン</th>
        </tr>
        <tr>
          <th>契約期間</th>
          <td>1ヶ月～</td>
        </tr>
        <tr>
          <th>料金</th>
          <td>￥3000～</td>
        </tr>
        <tr>
          <th>内容</th>
          <td>・人数最大8名<br>・オプション<br>・リモートデスクトップ機能<br>・１アカウント プラス10000円/月<br>・リモートデスクトップ機能版限定<br>・遠隔技術者サポートマッチング利用可能<br>・時間単位で依頼可能
          </td>
        </tr>
      </table>
    </div>
    <div class="pt-5">
      <table>
        <tr>
          <th colspan="2" class="text-title">
            オプションプラン<br>（Remote Engineer Support System）
          </th>
        </tr>
        <tr>
          <th>契約期間</th>
          <td>1時間～</td>
        </tr>
        <tr>
          <th>料金</th>
          <td>職務内容や支援日数により変動有 ※詳細はお問い合わせください。</td>
        </tr>
        <tr>
          <th>内容</th>
          <td>・リモートでの見積・設計・測量支援<br>・技術者を活用した各種リモート業務支援<br>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle";
export default {
  name: "FeeTable",
  components: { SectionTitle }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles'
.fee-table
    max-width: 1000px
    margin: 0 auto
table
    border-collapse: collapse
    border-spacing: 0
    width: 100%
    padding-top: 10px
table, th, td
    border: 1px solid #ffbc00
    border-collapse: collapse
table > tr
    border-bottom: 1px solid #eee
    margin-right: 15px
th, td
    padding: 10px
    text-align: left
    line-height: 20px
table > tr > th
    font-weight: bold
    font-size: 14px
    vertical-align: top
    min-width: 100px
    text-align: center
table > tr > td
    font-weight: normal
    font-size: 14px
.text-title
    background-color: #0D47A1
    color: #ffffff
    column-span: 2
@media #{map-get($display-breakpoints, 'sm-and-up')}
    table > tr > th
        font-weight: bold
        font-size: 14px
        vertical-align: top
        width: 200px
        text-align: center
</style>

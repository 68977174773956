import { render, staticRenderFns } from "./TypeThree.vue?vue&type=template&id=c6bb88c8&scoped=true&"
import script from "./TypeThree.vue?vue&type=script&lang=js&"
export * from "./TypeThree.vue?vue&type=script&lang=js&"
import style0 from "./TypeThree.vue?vue&type=style&index=0&id=c6bb88c8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6bb88c8",
  null
  
)

export default component.exports